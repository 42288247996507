<template>
  <div v-if="showStatus" class="flix-alert" :class="{'flix-alert-danger': error, 'flix-alert-success': !error}">
    {{ showStatus }}
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    status: String
  },
  data () {
    return {
      showStatus: false,
      error: false
    }
  },
  computed: {

  },
  mounted () {
    this.getStatus()
  },
  methods: {
    getStatus () {
      this.showStatus = this.status

      if (this.showStatus.toLowerCase().indexOf('error') === -1) {
        this.error = false
        return false
      }

      this.error = true
      var s = this.showStatus.split(' ')
      this.showStatus = this.showStatus.replace(s[s.length - 1], '')
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
